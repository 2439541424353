import { fromJS } from 'immutable';
import { clearError } from '../utils/errorHandle';
import {
  GET_GATE_LIST_REQUEST,
  GET_GATE_LIST_SUCCESS,
  GET_GATE_LIST_FAILURE,
  EDIT_GATE_REQUEST,
  EDIT_GATE_SUCCESS,
  EDIT_GATE_FAILURE,
  DELETE_GATE_REQUEST,
  DELETE_GATE_SUCCESS,
  DELETE_GATE_FAILURE,
  EDIT_ACCESS_GATE_GROUP_FAILURE,
  EDIT_ACCESS_GATE_GROUP_REQUEST,
  EDIT_ACCESS_GATE_GROUP_SUCCESS,
  GET_GATE_ACCESS_LIST_FAILURE,
  GET_GATE_ACCESS_LIST_REQUEST,
  GET_GATE_ACCESS_LIST_SUCCESS,
  DELETE_GATE_ACCESS_FAILURE,
  DELETE_GATE_ACCESS_REQUEST,
  DELETE_GATE_ACCESS_SUCCESS
} from '../actions/gateAction';

const initialState = fromJS({
  gateList: {},
  gateAccess: {},
  loading: false,
  error: '',
  deleteSuccess: false,
  saveSuccess: false,
  addGroupSuccess: false
});

const gate = (state = initialState, action) => {
  switch (action.type) {
    case GET_GATE_LIST_REQUEST: {
      clearError();
      return state.set('loading', true);
    }
    case GET_GATE_LIST_SUCCESS: {
      return state.set('gateList', action.gateList).set('loading', false);
    }
    case GET_GATE_LIST_FAILURE: {
      return state.set('error', action.error).set('loading', false);
    }
    case EDIT_GATE_REQUEST: {
      clearError();
      return state
        .set('error', '')
        .set('saveSuccess', false)
        .set('loading', true);
    }
    case EDIT_GATE_SUCCESS: {
      return state.set('saveSuccess', true).set('loading', false);
    }
    case EDIT_GATE_FAILURE: {
      return state
        .set('error', action.error)
        .set('saveSuccess', false)
        .set('loading', false);
    }
    case DELETE_GATE_REQUEST: {
      clearError();
      return state.set('loading', true).set('deleteSuccess', false);
    }
    case DELETE_GATE_SUCCESS: {
      return state.set('deleteSuccess', true);
    }
    case DELETE_GATE_FAILURE: {
      return state
        .set('error', action.error.response.error.message)
        .set('deleteSuccess', false);
    }
    case EDIT_ACCESS_GATE_GROUP_REQUEST: {
      clearError();
      return state.set('loading', true).set('addGroupSuccess', false);
    }
    case EDIT_ACCESS_GATE_GROUP_SUCCESS: {
      return state.set('addGroupSuccess', true);
    }
    case EDIT_ACCESS_GATE_GROUP_FAILURE: {
      return state
        .set('error', action.error.response.error.message)
        .set('addGroupSuccess', false);
    }
    case GET_GATE_ACCESS_LIST_REQUEST: {
      return state.set('loading', true);
    }
    case GET_GATE_ACCESS_LIST_SUCCESS: {
      return state.set('gateAccess', action.gateAccess).set('loading', false);
    }
    case GET_GATE_ACCESS_LIST_FAILURE: {
      return state.set('error', action.error).set('loading', false);
    }
    case DELETE_GATE_ACCESS_REQUEST: {
      clearError();
      return state.set('loading', true).set('deleteSuccess', false);
    }
    case DELETE_GATE_ACCESS_SUCCESS: {
      return state.set('deleteSuccess', true);
    }
    case DELETE_GATE_ACCESS_FAILURE: {
      return state
        .set('error', action.error.response.error.message)
        .set('deleteSuccess', false);
    }
    default: {
      return state;
    }
  }
};

export default gate;
