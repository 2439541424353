import React, { useState } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AuthorizedUserPage from '../AuthorizedUserApp/Loadable';
import LoginPage from '../LoginPage/Loadable';
import SetPasswordPage from '../SetPasswordPage/Loadable';
import { Detector } from 'react-detect-offline';
import { ReactComponent as SignalError } from '../../assets/images/connection-lost.svg';
import { ReactComponent as Error } from '../../assets/images/error.svg';
import Modal from '../../components/Modal/Loadable';

import '../../index.scss';

const history = createHistory();

const datePickerTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0a252e'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#152330',
        fontSize: '14px'
      },
      daySelected: {
        backgroundColor: '#0a252e'
      },
      current: {
        color: '#0a252e'
      }
    }
  }
});

export default function App() {
  window.addEventListener('resize', () => handleResize());
  const [isSupportedDevice, setIsSupportDevice] = useState(true);

  const handleResize = () => {
    let isSupport = true;
    if (window.innerWidth < 1350 || window.innerHeight < 625) {
      isSupport = false;
    }
    setIsSupportDevice(isSupport);
  };

  const supportedDevice = () => {
    if (window.innerWidth < 1350 || window.innerHeight < 625) {
      return false;
    }
    return true;
  };

  return (
    <MuiThemeProvider theme={datePickerTheme}>
      {isSupportedDevice && supportedDevice() ? (
        <React.Fragment>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <Switch>
                {localStorage.getItem('token') ? (
                  <Redirect exact from="/" to="/dashboard" />
                ) : (
                  <Redirect exact from="/" to="/login" />
                )}
                <Route path="/login" component={LoginPage} />
                <Route path="/dashboard" component={AuthorizedUserPage} />
                <Route
                  path="/setpassword"
                  render={() => (
                    <SetPasswordPage
                      location={history.location}
                      history={history}
                    />
                  )}
                />
                <Route component={AuthorizedUserPage} />
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
          <Detector
            render={({ online }) => (
              <Modal
                open={!online}
                title={
                  <h1 data-test="img-conection-lost-header">Connection Lost</h1>
                }
                image={
                  <SignalError
                    style={{ marginBottom: '25px' }}
                    data-test="img-no-wifi"
                  />
                }
                body={'Please check your network setting'}
                noBtn={true}
                data-test="modal-connection-lost"
              />
            )}
          />
        </React.Fragment>
      ) : (
        <div>
          <Modal
            open={true}
            title={<p>This device is not supported.</p>}
            image={<Error />}
            body={'Please use desktop version.'}
            noBtn={true}
          />
        </div>
      )}
    </MuiThemeProvider>
  );
}
