import { fromJS } from 'immutable';
import { clearError } from '../utils/errorHandle';
import {
  GET_EMPLOYEE_LIST_REQUEST,
  GET_EMPLOYEE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_FAILURE,
  GET_EMPLOYEE_LIST_DEPARTMENT_REQUEST,
  GET_EMPLOYEE_LIST_DEPARTMENT_SUCCESS,
  GET_EMPLOYEE_LIST_DEPARTMENT_FAILURE,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_BY_EMAIL_REQUEST,
  GET_EMPLOYEE_BY_EMAIL_SUCCESS,
  GET_EMPLOYEE_BY_EMAIL_FAILURE,
  GET_TIME_STAMP_REQUEST,
  GET_TIME_STAMP_SUCCESS,
  GET_TIME_STAMP_FAILURE,
  GET_EXPORT_TIME_STAMP_REQUEST,
  GET_EXPORT_TIME_STAMP_SUCCESS,
  GET_EXPORT_TIME_STAMP_FAILURE,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAILURE,
  GET_COUNT_REQUEST,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILURE,
  IMPORT_EMPLOYEE_REQUEST,
  IMPORT_EMPLOYEE_SUCCESS,
  IMPORT_EMPLOYEE_FAILURE,
  SET_DEFAULT_STATE
} from '../actions/employeeAction';

const initialState = fromJS({
  employeeList: {},
  employeeListDepartment: {},
  employeeDetail: {},
  timestamp: {},
  isLoading: false,
  isImportLoading: false,
  department: [],
  count: 0,
  importStatus: {},
  saveSuccess: false,
  deleteSuccess: false,
  newEmployee: []
});

const employee = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_LIST_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_EMPLOYEE_LIST_SUCCESS: {
      return state
        .set('employeeList', action.employeeList)
        .set('isLoading', false);
    }
    case GET_EMPLOYEE_LIST_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_EMPLOYEE_LIST_DEPARTMENT_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_EMPLOYEE_LIST_DEPARTMENT_SUCCESS: {
      return state
        .set('employeeListDepartment', action.employeeList)
        .set('isLoading', false);
    }
    case GET_EMPLOYEE_LIST_DEPARTMENT_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case ADD_EMPLOYEE_REQUEST: {
      clearError();
      return state.set('saveSuccess', false).set('deleteSuccess', false);
    }
    case ADD_EMPLOYEE_SUCCESS: {
      return state
        .set('saveSuccess', true)
        .set('newEmployee', [action.employee.uid]);
    }
    case ADD_EMPLOYEE_FAILURE: {
      return state.set('error', action.error).set('saveSuccess', false);
    }
    case EDIT_EMPLOYEE_REQUEST: {
      clearError();
      return state.set('saveSuccess', false);
    }
    case EDIT_EMPLOYEE_SUCCESS: {
      return state
        .set('employeeDetail', action.employee)
        .set('saveSuccess', true);
    }
    case EDIT_EMPLOYEE_FAILURE: {
      return state.set('error', action.error).set('saveSuccess', false);
    }
    case DELETE_EMPLOYEE_REQUEST: {
      clearError();
      return state.set('deleteSuccess', false);
    }
    case DELETE_EMPLOYEE_SUCCESS: {
      return state.set('deleteSuccess', true);
    }
    case DELETE_EMPLOYEE_FAILURE: {
      return state.set('error', action.error).set('deleteSuccess', false);
    }
    case GET_EMPLOYEE_REQUEST: {
      clearError();
      return state
        .set('isLoading', true)
        .set('saveSuccess', false)
        .set('deleteSuccess', false);
    }
    case GET_EMPLOYEE_SUCCESS: {
      return state
        .set('employeeDetail', action.employee)
        .set('isLoading', false);
    }
    case GET_EMPLOYEE_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_EMPLOYEE_BY_EMAIL_REQUEST: {
      clearError();
      return state
        .set('isLoading', true)
        .set('saveSuccess', false)
        .set('deleteSuccess', false);
    }
    case GET_EMPLOYEE_BY_EMAIL_SUCCESS: {
      return state
        .set('employeeDetail', action.employee)
        .set('isLoading', false);
    }
    case GET_EMPLOYEE_BY_EMAIL_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_TIME_STAMP_REQUEST: {
      clearError();
      return state.set('timestamp', {
        isLoading: true
      });
    }
    case GET_TIME_STAMP_SUCCESS: {
      return state.set('timestamp', {
        table: action.timestamp,
        isLoading: false
      });
    }
    case GET_TIME_STAMP_FAILURE: {
      return state.set('error', action.error).set('timestamp', {
        isLoading: false
      });
    }
    case GET_EXPORT_TIME_STAMP_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_EXPORT_TIME_STAMP_SUCCESS: {
      return state.set('isLoading', false);
    }
    case GET_EXPORT_TIME_STAMP_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_DEPARTMENT_REQUEST: {
      clearError();
      return state;
    }
    case GET_DEPARTMENT_SUCCESS: {
      return state.set('department', action.department);
    }
    case GET_DEPARTMENT_FAILURE: {
      return state.set('error', action.error);
    }
    case GET_COUNT_REQUEST: {
      clearError();
      return state;
    }
    case GET_COUNT_SUCCESS: {
      return state.set('count', action.count);
    }
    case GET_COUNT_FAILURE: {
      return state.set('error', action.error);
    }
    case IMPORT_EMPLOYEE_REQUEST: {
      clearError();
      return state.set('importStatus', {}).set('isImportLoading', true);
    }
    case IMPORT_EMPLOYEE_SUCCESS: {
      return state
        .set('importStatus', action.response)
        .set('newEmployee', action.response.success)
        .set('isImportLoading', false);
    }
    case IMPORT_EMPLOYEE_FAILURE: {
      return state.set('error', action.error).set('isImportLoading', false);
    }
    case SET_DEFAULT_STATE: {
      return state
        .set('saveSuccess', false)
        .set('deleteSuccess', false)
        .set('isLoading', false)
        .set('isImportLoading', false)
        .set('newEmployee', []);
    }
    default: {
      return state;
    }
  }
};

export default employee;
