import { clearToken } from './tokenService';
export function getError() {
  return localStorage.getItem('error');
}

export function clearError() {
  localStorage.setItem('error', '');
}

export function setError(error) {
  if (error) {
    localStorage.setItem('error', error);
    if (error === 'Unauthorized') {
      clearToken();
    }
  }
}

export default {
  getError,
  clearError,
  setError
};
