import { combineReducers } from 'redux';
import account from './accountReducer';
import user from './userReducer';
import log from './logReducer';
import employee from './employeeReducer';
import visitor from './visitorReducer';
import gate from './gateReducer';
import device from './deviceReducer';
import group from './groupReducer';

export default combineReducers({
  account,
  user,
  log,
  employee,
  visitor,
  gate,
  device,
  group
});
