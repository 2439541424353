import { fromJS } from 'immutable';
import { clearError } from '../utils/errorHandle';
import {
  GET_GROUP_LIST_REQUEST,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_LIST_FAILURE,
  GET_GROUP_MEMBER_LIST_REQUEST,
  GET_GROUP_MEMBER_LIST_SUCCESS,
  GET_GROUP_MEMBER_LIST_FAILURE,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  DELETE_GROUP_MEMBER_REQUEST,
  DELETE_GROUP_MEMBER_SUCCESS,
  DELETE_GROUP_MEMBER_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  SET_DEFAULT_STATE
} from '../actions/groupAction';

const initialState = fromJS({
  groupList: {},
  groupMemberList: {},
  loading: false,
  error: '',
  deleteSuccess: false,
  saveSuccess: false,
  addSuccess: false,
  duplicateGroup: false,
  groupDetail: {}
});

const group = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_LIST_REQUEST: {
      clearError();
      return state.set('loading', true);
    }
    case GET_GROUP_LIST_SUCCESS: {
      return state.set('groupList', action.groupList).set('loading', false);
    }
    case GET_GROUP_LIST_FAILURE: {
      return state.set('error', action.error).set('loading', false);
    }
    case GET_GROUP_MEMBER_LIST_REQUEST: {
      clearError();
      return state.set('loading', true);
    }
    case GET_GROUP_MEMBER_LIST_SUCCESS: {
      return state
        .set('groupMemberList', action.groupMemberList)
        .set('loading', false);
    }
    case GET_GROUP_MEMBER_LIST_FAILURE: {
      return state.set('error', action.error).set('loading', false);
    }
    case EDIT_GROUP_REQUEST: {
      clearError();
      return state.set('error', '').set('saveSuccess', false);
    }
    case EDIT_GROUP_SUCCESS: {
      return state.set('saveSuccess', true).set('groupDetail', action.response);
    }
    case EDIT_GROUP_FAILURE: {
      return state.set('error', action.error).set('saveSuccess', false);
    }
    case ADD_GROUP_REQUEST: {
      clearError();
      return state.set('loading', true).set('addSuccess', false);
    }
    case ADD_GROUP_SUCCESS: {
      return state
        .set('addSuccess', true)
        .set('groupDetail', action.group)
        .set('duplicateGroup', false);
    }
    case ADD_GROUP_FAILURE: {
      return state
        .set('error', action.error)
        .set('addSuccess', false)
        .set('duplicateGroup', true);
    }
    case DELETE_GROUP_MEMBER_REQUEST: {
      clearError();
      return state.set('loading', true).set('deleteSuccess', false);
    }
    case DELETE_GROUP_MEMBER_SUCCESS: {
      return state.set('deleteSuccess', true);
    }
    case DELETE_GROUP_MEMBER_FAILURE: {
      return state
        .set('error', action.error.response.error.message)
        .set('deleteSuccess', false);
    }
    case DELETE_GROUP_REQUEST: {
      clearError();
      return state.set('loading', true).set('deleteSuccess', false);
    }
    case DELETE_GROUP_SUCCESS: {
      return state.set('deleteSuccess', true);
    }
    case DELETE_GROUP_FAILURE: {
      console.log(action);
      return state
        .set('error', action.error.response.error.message)
        .set('deleteSuccess', false);
    }
    case SET_DEFAULT_STATE: {
      return state.set('duplicateGroup', false);
    }
    default: {
      return state;
    }
  }
};

export default group;
