import queryString from 'query-string';
import { get, get_file, download } from '../utils';
import { TIME_STAMP_URL } from '../utils/apis';
import { setError } from '../utils/errorHandle';
import moment from 'moment';

export const GET_LOG_REQUEST = 'GET_LOG_REQUEST';
export const GET_LOG_SUCCESS = 'GET_LOG_SUCCESS';
export const GET_LOG_FAILURE = 'GET_LOG_FAILURE';

const getLogRequest = () => {
  return {
    type: GET_LOG_REQUEST
  };
};

const getLogSuccess = log => {
  return {
    type: GET_LOG_SUCCESS,
    log
  };
};

const getLogFailure = error => {
  return {
    type: GET_LOG_FAILURE,
    error
  };
};

export const getLog = () => {
  return async dispatch => {
    dispatch(getLogRequest());
    try {
      const response = await get(`${TIME_STAMP_URL}/recently`);
      dispatch(getLogSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getLogFailure(err));
    }
  };
};

export const GET_PREVIEW_LOG_REQUEST = 'GET_PREVIEW_LOG_REQUEST';
export const GET_PREVIEW_LOG_SUCCESS = 'GET_PREVIEW_LOG_SUCCESS';
export const GET_PREVIEW_LOG_FAILURE = 'GET_PREVIEW_LOG_FAILURE';

const getPreviewLogRequest = () => {
  return {
    type: GET_PREVIEW_LOG_REQUEST
  };
};

const getPreviewLogSuccess = log => {
  return {
    type: GET_PREVIEW_LOG_SUCCESS,
    log
  };
};

const getPreviewLogFailure = error => {
  return {
    type: GET_PREVIEW_LOG_FAILURE,
    error
  };
};

export const getPreviewLog = query => {
  return async dispatch => {
    dispatch(getPreviewLogRequest());
    try {
      const response = await get(
        `${TIME_STAMP_URL}/export/preview?${queryString.stringify(query)}`
      );
      dispatch(getPreviewLogSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getPreviewLogFailure(err));
    }
  };
};

export const GET_EXPORT_LOG_REQUEST = 'GET_EXPORT_LOG_REQUEST';
export const GET_EXPORT_LOG_SUCCESS = 'GET_EXPORT_LOG_SUCCESS';
export const GET_EXPORT_LOG_FAILURE = 'GET_EXPORT_LOG_FAILURE';

const getExportLogRequest = () => {
  return {
    type: GET_EXPORT_LOG_REQUEST
  };
};

const getExportLogSuccess = log => {
  return {
    type: GET_EXPORT_LOG_SUCCESS,
    log
  };
};

const getExportLogFailure = error => {
  return {
    type: GET_EXPORT_LOG_FAILURE,
    error
  };
};

export const getExportLog = query => {
  return async dispatch => {
    dispatch(getExportLogRequest());
    try {
      const response = await get_file(
        `${TIME_STAMP_URL}/export?${queryString.stringify(query)}`
      );
      const blob = await response.blob();
      await download(
        blob,
        `export_timestamp_${moment().format('L')}.${query.fileType}`
      );
      dispatch(getExportLogSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getExportLogFailure(err));
    }
  };
};

export const GET_CHECKIN_LOG_REQUEST = 'GET_CHECKIN_LOG_REQUEST';
export const GET_CHECKIN_LOG_SUCCESS = 'GET_CHECKIN_LOG_SUCCESS';
export const GET_CHECKIN_LOG_FAILURE = 'GET_CHECKIN_LOG_FAILURE';

const getCheckInLogRequest = () => {
  return {
    type: GET_CHECKIN_LOG_REQUEST
  };
};

const getCheckInLogSuccess = log => {
  return {
    type: GET_CHECKIN_LOG_SUCCESS,
    log
  };
};

const getCheckInLogFailure = error => {
  return {
    type: GET_CHECKIN_LOG_FAILURE,
    error
  };
};

export const getCheckInLog = () => {
  return async dispatch => {
    dispatch(getCheckInLogRequest());
    try {
      const response = await get(`${TIME_STAMP_URL}/today`);
      dispatch(getCheckInLogSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getCheckInLogFailure(err));
    }
  };
};

export const GET_IN_BUILDING_LOG_REQUEST = 'GET_IN_BUILDING_LOG_REQUEST';
export const GET_IN_BUILDING_LOG_SUCCESS = 'GET_IN_BUILDING_LOG_SUCCESS';
export const GET_IN_BUILDING_LOG_FAILURE = 'GET_IN_BUILDING_LOG_FAILURE';

const getInBuildingLogRequest = () => {
  return {
    type: GET_IN_BUILDING_LOG_REQUEST
  };
};

const getInBuildingLogSuccess = log => {
  return {
    type: GET_IN_BUILDING_LOG_SUCCESS,
    log
  };
};

const getInBuildingLogFailure = error => {
  return {
    type: GET_IN_BUILDING_LOG_FAILURE,
    error
  };
};

export const getInBuildingLog = () => {
  return async dispatch => {
    dispatch(getInBuildingLogRequest());
    try {
      const response = await get(`${TIME_STAMP_URL}/today/in`);
      dispatch(getInBuildingLogSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getInBuildingLogFailure(err));
    }
  };
};
