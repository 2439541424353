import { fromJS } from 'immutable';
import { clearError } from '../utils/errorHandle';
import {
  GET_LOG_REQUEST,
  GET_LOG_SUCCESS,
  GET_LOG_FAILURE,
  GET_PREVIEW_LOG_REQUEST,
  GET_PREVIEW_LOG_SUCCESS,
  GET_PREVIEW_LOG_FAILURE,
  GET_EXPORT_LOG_REQUEST,
  GET_EXPORT_LOG_SUCCESS,
  GET_EXPORT_LOG_FAILURE,
  GET_CHECKIN_LOG_REQUEST,
  GET_CHECKIN_LOG_SUCCESS,
  GET_CHECKIN_LOG_FAILURE,
  GET_IN_BUILDING_LOG_REQUEST,
  GET_IN_BUILDING_LOG_SUCCESS,
  GET_IN_BUILDING_LOG_FAILURE
} from '../actions/logAction';

const initialState = fromJS({
  logResult: [],
  previewLog: [],
  isLoading: false,
  checkInLog: [],
  inBuildingLog: []
});

const log = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOG_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_LOG_SUCCESS: {
      return state.set('logResult', action.log).set('isLoading', false);
    }
    case GET_LOG_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_PREVIEW_LOG_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_PREVIEW_LOG_SUCCESS: {
      return state.set('previewLog', action.log).set('isLoading', false);
    }
    case GET_PREVIEW_LOG_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_EXPORT_LOG_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_EXPORT_LOG_SUCCESS: {
      return state.set('isLoading', false);
    }
    case GET_EXPORT_LOG_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_CHECKIN_LOG_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_CHECKIN_LOG_SUCCESS: {
      return state.set('checkInLog', action.log).set('isLoading', false);
    }
    case GET_CHECKIN_LOG_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case GET_IN_BUILDING_LOG_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_IN_BUILDING_LOG_SUCCESS: {
      return state.set('inBuildingLog', action.log).set('isLoading', false);
    }
    case GET_IN_BUILDING_LOG_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    default: {
      return state;
    }
  }
};

export default log;
