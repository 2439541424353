export function getToken() {
  return localStorage.getItem('token');
}

export function clearToken() {
  localStorage.setItem('token', '');
}

export function setToken(credential) {
  if (credential.token) {
    localStorage.setItem('token', credential.token);
  }
}

export default {
  getToken,
  clearToken,
  setToken
};
