import queryString from 'query-string';
import { get, patch, post } from '../utils';
import { GATE_URL } from '../utils/apis';
import { setError } from '../utils/errorHandle';

export const GET_GATE_LIST_REQUEST = 'GET_GATE_LIST_REQUEST';
export const GET_GATE_LIST_SUCCESS = 'GET_GATE_LIST_SUCCESS';
export const GET_GATE_LIST_FAILURE = 'GET_GATE_LIST_FAILURE';

const getGateListRequest = () => {
  return {
    type: GET_GATE_LIST_REQUEST
  };
};

const getGateListSuccess = gateList => {
  return {
    type: GET_GATE_LIST_SUCCESS,
    gateList
  };
};

const getGateListFailure = error => {
  return {
    type: GET_GATE_LIST_FAILURE,
    error
  };
};

export const getGateList = query => {
  let gateQuery = Object.assign({}, query, {
    [query.column]: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getGateListRequest());
    try {
      const response = await get(
        `${GATE_URL}/list?${queryString.stringify(gateQuery)}`
      );
      dispatch(getGateListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getGateListFailure(err));
    }
  };
};

export const EDIT_GATE_REQUEST = 'EDIT_GATE_REQUEST';
export const EDIT_GATE_SUCCESS = 'EDIT_GATE_SUCCESS';
export const EDIT_GATE_FAILURE = 'EDIT_GATE_FAILURE';

const editGateRequest = () => {
  return {
    type: EDIT_GATE_REQUEST
  };
};

const editGateSuccess = response => {
  return {
    type: EDIT_GATE_SUCCESS,
    response
  };
};

const editGateFailure = error => {
  return {
    type: EDIT_GATE_FAILURE,
    error
  };
};

export const editGate = gate => {
  return async dispatch => {
    dispatch(editGateRequest());
    try {
      const response = await patch(`${GATE_URL}`, gate);
      dispatch(editGateSuccess(response));
      dispatch(getGateList({ id: gate.id }));
    } catch (err) {
      setError(err.message);
      dispatch(editGateFailure(err));
    }
  };
};

export const DELETE_GATE_REQUEST = 'DELETE_GATE_REQUEST';
export const DELETE_GATE_SUCCESS = 'DELETE_GATE_SUCCESS';
export const DELETE_GATE_FAILURE = 'DELETE_GATE_FAILURE';

const deleteGateRequest = () => {
  return {
    type: DELETE_GATE_REQUEST
  };
};

const deleteGateSuccess = response => {
  return {
    type: DELETE_GATE_SUCCESS,
    response
  };
};

const deleteGateFailure = error => {
  return {
    type: DELETE_GATE_FAILURE,
    error
  };
};

export const deleteGate = gate => {
  let body = {
    ids: gate
  };
  return async dispatch => {
    dispatch(deleteGateRequest());
    try {
      const response = await post(`${GATE_URL}/delete`, body);
      dispatch(deleteGateSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(deleteGateFailure(err));
    }
  };
};

export const EDIT_ACCESS_GATE_GROUP_REQUEST = 'EDIT_ACCESS_GATE_GROUP_REQUEST';
export const EDIT_ACCESS_GATE_GROUP_SUCCESS = 'EDIT_ACCESS_GATE_GROUP_SUCCESS';
export const EDIT_ACCESS_GATE_GROUP_FAILURE = 'EDIT_ACCESS_GATE_GROUP_FAILURE';

const editAccessGateGroupRequest = () => {
  return {
    type: EDIT_ACCESS_GATE_GROUP_REQUEST
  };
};

const editAccessGateGroupSuccess = response => {
  return {
    type: EDIT_ACCESS_GATE_GROUP_SUCCESS,
    response
  };
};

const editAccessGateGroupFailure = error => {
  return {
    type: EDIT_ACCESS_GATE_GROUP_FAILURE,
    error
  };
};

export const editAccessGateGroup = body => {
  return async dispatch => {
    dispatch(editAccessGateGroupRequest());
    try {
      const response = await patch(`${GATE_URL}/access`, body);
      dispatch(editAccessGateGroupSuccess(response));
      dispatch(getGateAccessList({ id: body.id }));
    } catch (err) {
      setError(err.message);
      dispatch(editAccessGateGroupFailure(err));
    }
  };
};

export const GET_GATE_ACCESS_LIST_REQUEST = 'GET_GATE_ACCESS_LIST_REQUEST';
export const GET_GATE_ACCESS_LIST_SUCCESS = 'GET_GATE_ACCESS_LIST_SUCCESS';
export const GET_GATE_ACCESS_LIST_FAILURE = 'GET_GATE_ACCESS_LIST_FAILURE';

const getGateAccessListRequest = () => {
  return {
    type: GET_GATE_ACCESS_LIST_REQUEST
  };
};

const getGateAccessListSuccess = gateAccess => {
  return {
    type: GET_GATE_ACCESS_LIST_SUCCESS,
    gateAccess
  };
};

const getGateAccessListFailure = error => {
  return {
    type: GET_GATE_ACCESS_LIST_FAILURE,
    error
  };
};

export const getGateAccessList = query => {
  let gateAccessQuery = Object.assign({}, query, {
    [query.column]: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getGateAccessListRequest());
    try {
      const response = await get(
        `${GATE_URL}/access/list?${queryString.stringify(gateAccessQuery)}`
      );
      dispatch(getGateAccessListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getGateAccessListFailure(err));
    }
  };
};

export const DELETE_GATE_ACCESS_REQUEST = 'DELETE_GATE_ACCESS_REQUEST';
export const DELETE_GATE_ACCESS_SUCCESS = 'DELETE_GATE_ACCESS_SUCCESS';
export const DELETE_GATE_ACCESS_FAILURE = 'DELETE_GATE_ACCESS_FAILURE';

const deleteGateAccessRequest = () => {
  return {
    type: DELETE_GATE_ACCESS_REQUEST
  };
};

const deleteGateAccessSuccess = response => {
  return {
    type: DELETE_GATE_ACCESS_SUCCESS,
    response
  };
};

const deleteGateAccessFailure = error => {
  return {
    type: DELETE_GATE_ACCESS_FAILURE,
    error
  };
};

export const deleteGateAccess = body => {
  return async dispatch => {
    dispatch(deleteGateAccessRequest());
    try {
      const response = await post(`${GATE_URL}/access/delete`, body);
      dispatch(deleteGateAccessSuccess(response));
      dispatch(getGateAccessList({ id: body.id }));
    } catch (err) {
      setError(err.message);
      dispatch(deleteGateAccessFailure(err));
    }
  };
};
