import { fromJS } from 'immutable';
import { clearError } from '../utils/errorHandle';
import {
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_EMPLOYEE_REQUEST,
  INVITE_EMPLOYEE_SUCCESS,
  INVITE_EMPLOYEE_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SET_CHANGE_PASSWORD_TO_FALSE,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  HIDE_SUCCESS_MODAL
} from '../actions/userAction';

const initialState = fromJS({
  userList: {},
  loading: false,
  error: '',
  showSuccessAddModal: false,
  deleteSuccess: false,
  addSuccess: false,
  saveSuccess: false,
  changePasswordSuccess: false
});

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_LIST_REQUEST: {
      clearError();
      return state.set('loading', true);
    }
    case GET_USER_LIST_SUCCESS: {
      return state.set('userList', action.userList).set('loading', false);
    }
    case GET_USER_LIST_FAILURE: {
      return state.set('error', action.error).set('loading', false);
    }
    case INVITE_USER_REQUEST: {
      clearError();
      return state;
    }
    case INVITE_USER_SUCCESS: {
      return state.set('showSuccessAddModal', true);
    }
    case INVITE_USER_FAILURE: {
      return state.set('error', action.error);
    }
    case INVITE_EMPLOYEE_REQUEST: {
      clearError();
      return state;
    }
    case INVITE_EMPLOYEE_SUCCESS: {
      return state.set('showSuccessAddModal', true);
    }
    case INVITE_EMPLOYEE_FAILURE: {
      return state.set('error', action.error);
    }
    case HIDE_SUCCESS_MODAL: {
      return state.set('showSuccessAddModal', false);
    }
    case ADD_USER_REQUEST: {
      clearError();
      return state.set('error', '');
    }
    case ADD_USER_SUCCESS: {
      return state.set('addSuccess', true);
    }
    case ADD_USER_FAILURE: {
      return state.set('error', action.error.response.error.message);
    }
    case CHANGE_PASSWORD_REQUEST: {
      clearError();
      return state.set('error', '').set('changePasswordSuccess', false);
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return state.set('changePasswordSuccess', true);
    }
    case CHANGE_PASSWORD_FAILURE: {
      return state
        .set('error', action.error.response.error.message)
        .set('changePasswordSuccess', false);
    }
    case SET_CHANGE_PASSWORD_TO_FALSE: {
      return state.set('changePasswordSuccess', false);
    }
    case EDIT_ROLE_REQUEST: {
      clearError();
      return state.set('error', '').set('saveSuccess', false);
    }
    case EDIT_ROLE_SUCCESS: {
      return state.set('saveSuccess', true);
    }
    case EDIT_ROLE_FAILURE: {
      return state.set('error', action.error).set('saveSuccess', false);
    }
    case DELETE_USER_REQUEST: {
      return state.set('deleteSuccess', false);
    }
    case DELETE_USER_SUCCESS: {
      return state.set('deleteSuccess', true);
    }
    case DELETE_USER_FAILURE: {
      return state.set('error', action.error).set('deleteSuccess', false);
    }
    default: {
      return state;
    }
  }
};

export default user;
