import 'whatwg-fetch';
import { getToken } from './tokenService';
const SERVER_URL = process.env.SERVER_URL;

export function call(url, method, data) {
  const token = getToken();
  const serverUrl = `${SERVER_URL}${url}`;
  return fetch(serverUrl, {
    method: method,
    // credentials: "include",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })
    .then(parseJSON)
    .then(checkHttpStatus)
    .catch(error => {
      // No response from the server
      if (typeof error.response === 'undefined') {
        error.response = {
          status: 408,
          message: 'Cannot connect to the server'
        };
      }
      throw error;
    });
}

export function callUpload(url, method, formData) {
  const token = getToken();
  const serverUrl = `${SERVER_URL}${url}`;
  return fetch(serverUrl, {
    method: method,
    // credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData
  })
    .then(parseJSON)
    .then(checkHttpStatus)
    .catch(error => {
      // No response from the server
      if (typeof error.response === 'undefined') {
        error.response = {
          status: 408,
          message: 'Cannot connect to the server'
        };
      }
      throw error;
    });
}

export function get(url) {
  return call(url, 'GET');
}

export function post(url, data) {
  return call(url, 'POST', data);
}

export function put(url, data) {
  return call(url, 'PUT', data);
}

export function del(url, data) {
  return call(url, 'DELETE', data);
}

export function patch(url, data) {
  return call(url, 'PATCH', data);
}

export function postUpload(url, formData) {
  return callUpload(url, 'POST', formData);
}

export function patchUpload(url, formData) {
  return callUpload(url, 'PATCH', formData);
}

export function get_file(url) {
  const token = getToken();
  const serverUrl = `${SERVER_URL}${url}`;
  return fetch(serverUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
}

export function download(blob, fileName) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.download = fileName;
  a.click();
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.body;
  } else {
    var error = new Error(response.statusText);
    error.response = response.body;
    error.status = response.status;
    throw error;
  }
}

export function parseJSON(response) {
  return response
    .json()
    .then(function(body) {
      return {
        status: response.status,
        statusText: response.statusText,
        body: body
      };
    })
    .catch(function(e) {
      return response;
    });
}
