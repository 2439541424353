import moment from 'moment';
import queryString from 'query-string';
import { get, post, patch, get_file, postUpload, download } from '../utils';
import { EMPLOYEE_URL, TIME_STAMP_URL } from '../utils/apis';
import { setError } from '../utils/errorHandle';

export const GET_EMPLOYEE_LIST_REQUEST = 'GET_EMPLOYEE_LIST_REQUEST';
export const GET_EMPLOYEE_LIST_SUCCESS = 'GET_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_LIST_FAILURE = 'GET_EMPLOYEE_LIST_FAILURE';

const getEmployeeListRequest = () => {
  return {
    type: GET_EMPLOYEE_LIST_REQUEST
  };
};

const getEmployeeListSuccess = employeeList => {
  return {
    type: GET_EMPLOYEE_LIST_SUCCESS,
    employeeList
  };
};

const getEmployeeListFailure = error => {
  return {
    type: GET_EMPLOYEE_LIST_FAILURE,
    error
  };
};

export const getEmployeeList = query => {
  let employeeQuery = Object.assign({}, query, {
    [query.column]: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getEmployeeListRequest());
    try {
      const response = await get(
        `${EMPLOYEE_URL}/list?${queryString.stringify(employeeQuery)}`
      );
      dispatch(getEmployeeListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getEmployeeListFailure(err));
    }
  };
};

export const GET_EMPLOYEE_LIST_DEPARTMENT_REQUEST =
  'GET_EMPLOYEE_LIST_DEPARTMENT_REQUEST';
export const GET_EMPLOYEE_LIST_DEPARTMENT_SUCCESS =
  'GET_EMPLOYEE_LIST_DEPARTMENT_SUCCESS';
export const GET_EMPLOYEE_LIST_DEPARTMENT_FAILURE =
  'GET_EMPLOYEE_LIST_DEPARTMENT_FAILURE';

const getEmployeeListDepartmentRequest = () => {
  return {
    type: GET_EMPLOYEE_LIST_DEPARTMENT_REQUEST
  };
};

const getEmployeeListDepartmentSuccess = employeeList => {
  return {
    type: GET_EMPLOYEE_LIST_DEPARTMENT_SUCCESS,
    employeeList
  };
};

const getEmployeeListDepartmentFailure = error => {
  return {
    type: GET_EMPLOYEE_LIST_DEPARTMENT_FAILURE,
    error
  };
};

export const getEmployeeListDepartment = query => {
  let employeeDepartmentQuery = Object.assign({}, query, {
    [query.column]: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getEmployeeListDepartmentRequest());
    try {
      const response = await get(
        `${EMPLOYEE_URL}/list/department?${queryString.stringify(
          employeeDepartmentQuery
        )}`
      );
      dispatch(getEmployeeListDepartmentSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getEmployeeListDepartmentFailure(err));
    }
  };
};

export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';

const addEmployeeRequest = () => {
  return {
    type: ADD_EMPLOYEE_REQUEST
  };
};

const addEmployeeSuccess = employee => {
  return {
    type: ADD_EMPLOYEE_SUCCESS,
    employee
  };
};

const addEmployeeFailure = error => {
  return {
    type: ADD_EMPLOYEE_FAILURE,
    error
  };
};

export const addEmployee = data => {
  return async dispatch => {
    dispatch(addEmployeeRequest());
    try {
      const response = await post(`${EMPLOYEE_URL}`, data);
      dispatch(addEmployeeSuccess(response));
      dispatch(
        getEmployeeList({
          column: '',
          orderBy: 'updatedAt',
          direction: 'desc',
          page: 0
        })
      );
      dispatch(getDepartment());
    } catch (err) {
      setError(err.message);
      dispatch(addEmployeeFailure(err));
    }
  };
};

export const EDIT_EMPLOYEE_REQUEST = 'EDIT_EMPLOYEE_REQUEST';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE';

const editEmployeeRequest = () => {
  return {
    type: EDIT_EMPLOYEE_REQUEST
  };
};

const editEmployeeSuccess = employee => {
  return {
    type: EDIT_EMPLOYEE_SUCCESS,
    employee
  };
};

const editEmployeeFailure = error => {
  return {
    type: EDIT_EMPLOYEE_FAILURE,
    error
  };
};

export const editEmployee = data => {
  return async dispatch => {
    dispatch(editEmployeeRequest());
    try {
      const response = await patch(`${EMPLOYEE_URL}`, data);
      dispatch(editEmployeeSuccess(response));
      dispatch(
        getEmployeeList({
          column: '',
          orderBy: 'updatedAt',
          direction: 'desc',
          page: 0
        })
      );
      dispatch(getDepartment());
    } catch (err) {
      setError(err.message);
      dispatch(editEmployeeFailure(err));
    }
  };
};

export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

const deleteEmployeeRequest = () => {
  return {
    type: DELETE_EMPLOYEE_REQUEST
  };
};

const deleteEmployeeSuccess = employee => {
  return {
    type: DELETE_EMPLOYEE_SUCCESS,
    employee
  };
};

const deleteEmployeeFailure = error => {
  return {
    type: DELETE_EMPLOYEE_FAILURE,
    error
  };
};

export const deleteEmployee = data => {
  let body = {
    uids: data
  };
  return async dispatch => {
    dispatch(deleteEmployeeRequest());
    try {
      const response = await post(`${EMPLOYEE_URL}/delete`, body);
      dispatch(deleteEmployeeSuccess(response));
      dispatch(
        getEmployeeList({
          column: '',
          orderBy: 'updatedAt',
          direction: 'desc',
          page: 0
        })
      );
    } catch (err) {
      setError(err.message);
      dispatch(deleteEmployeeFailure(err));
    }
  };
};

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';

const getEmployeeRequest = () => {
  return {
    type: GET_EMPLOYEE_REQUEST
  };
};

const getEmployeeSuccess = employee => {
  return {
    type: GET_EMPLOYEE_SUCCESS,
    employee
  };
};

const getEmployeeFailure = error => {
  return {
    type: GET_EMPLOYEE_FAILURE,
    error
  };
};

export const getEmployee = uid => {
  return async dispatch => {
    dispatch(getEmployeeRequest());
    try {
      const response = await get(`${EMPLOYEE_URL}?uid=${uid}`);
      dispatch(getEmployeeSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getEmployeeFailure(err));
    }
  };
};

export const GET_EMPLOYEE_BY_EMAIL_REQUEST = 'GET_EMPLOYEE_BY_EMAIL_REQUEST';
export const GET_EMPLOYEE_BY_EMAIL_SUCCESS = 'GET_EMPLOYEE_BY_EMAIL_SUCCESS';
export const GET_EMPLOYEE_BY_EMAIL_FAILURE = 'GET_EMPLOYEE_BY_EMAIL_FAILURE';

const getEmployeeByEmailRequest = () => {
  return {
    type: GET_EMPLOYEE_BY_EMAIL_REQUEST
  };
};

const getEmployeeByEmailSuccess = employee => {
  return {
    type: GET_EMPLOYEE_BY_EMAIL_SUCCESS,
    employee
  };
};

const getEmployeeByEmailFailure = error => {
  return {
    type: GET_EMPLOYEE_BY_EMAIL_FAILURE,
    error
  };
};

export const getEmployeeByEmail = email => {
  return async dispatch => {
    dispatch(getEmployeeByEmailRequest());
    try {
      const response = await get(`${EMPLOYEE_URL}/email?email=${email}`);
      dispatch(getEmployeeByEmailSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getEmployeeByEmailFailure(err));
    }
  };
};

export const GET_TIME_STAMP_REQUEST = 'GET_TIME_STAMP_REQUEST';
export const GET_TIME_STAMP_SUCCESS = 'GET_TIME_STAMP_SUCCESS';
export const GET_TIME_STAMP_FAILURE = 'GET_TIME_STAMP_FAILURE';

const getTimestampRequest = () => {
  return {
    type: GET_TIME_STAMP_REQUEST
  };
};

const getTimestampSuccess = timestamp => {
  return {
    type: GET_TIME_STAMP_SUCCESS,
    timestamp
  };
};

const getTimestampFailure = error => {
  return {
    type: GET_TIME_STAMP_FAILURE,
    error
  };
};

export const getTimestamp = uid => {
  return async dispatch => {
    dispatch(getTimestampRequest());
    try {
      const response = await get(`${TIME_STAMP_URL}/workinghours?uid=${uid}`);
      dispatch(getTimestampSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getTimestampFailure(err));
    }
  };
};

export const GET_EXPORT_TIME_STAMP_REQUEST = 'GET_EXPORT_TIME_STAMP_REQUEST';
export const GET_EXPORT_TIME_STAMP_SUCCESS = 'GET_EXPORT_TIME_STAMP_SUCCESS';
export const GET_EXPORT_TIME_STAMP_FAILURE = 'GET_EXPORT_TIME_STAMP_FAILURE';

const getExportTimestampRequest = () => {
  return {
    type: GET_EXPORT_TIME_STAMP_REQUEST
  };
};

const getExportTimestampSuccess = () => {
  return {
    type: GET_EXPORT_TIME_STAMP_SUCCESS
  };
};

const getExportTimestampFailure = error => {
  return {
    type: GET_EXPORT_TIME_STAMP_FAILURE,
    error
  };
};

export const getExportTimestamp = data => {
  return async dispatch => {
    dispatch(getExportTimestampRequest());
    try {
      const response = await get_file(
        `${TIME_STAMP_URL}/workinghours/export?uid=${data.uid}`
      );
      const blob = await response.blob();
      await download(
        blob,
        `export_${data.uid}_${moment().format('L')}.${data.fileType}`
      );
      dispatch(getExportTimestampSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getExportTimestampFailure(err));
    }
  };
};

export const GET_DEPARTMENT_REQUEST = 'GET_DEPARTMENT_REQUEST';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAILURE = 'GET_DEPARTMENT_FAILURE';

const getDepartmentRequest = () => {
  return {
    type: GET_DEPARTMENT_REQUEST
  };
};

const getDepartmentSuccess = department => {
  return {
    type: GET_DEPARTMENT_SUCCESS,
    department
  };
};

const getDepartmentFailure = error => {
  return {
    type: GET_DEPARTMENT_FAILURE,
    error
  };
};

export const getDepartment = () => {
  return async dispatch => {
    dispatch(getDepartmentRequest());
    try {
      const response = await get(`${EMPLOYEE_URL}/department`);
      dispatch(getDepartmentSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getDepartmentFailure(err));
    }
  };
};

export const GET_COUNT_REQUEST = 'GET_COUNT_REQUEST';
export const GET_COUNT_SUCCESS = 'GET_COUNT_SUCCESS';
export const GET_COUNT_FAILURE = 'GET_COUNT_FAILURE';

const getCountRequest = () => {
  return {
    type: GET_COUNT_REQUEST
  };
};

const getCountSuccess = count => {
  return {
    type: GET_COUNT_SUCCESS,
    count
  };
};

const getCountFailure = error => {
  return {
    type: GET_COUNT_FAILURE,
    error
  };
};

export const getCount = () => {
  return async dispatch => {
    dispatch(getCountRequest());
    try {
      const response = await get(`${TIME_STAMP_URL}/count`);
      dispatch(getCountSuccess(response.count));
    } catch (err) {
      setError(err.message);
      dispatch(getCountFailure(err));
    }
  };
};

export const IMPORT_EMPLOYEE_REQUEST = 'IMPORT_EMPLOYEE_REQUEST';
export const IMPORT_EMPLOYEE_SUCCESS = 'IMPORT_EMPLOYEE_SUCCESS';
export const IMPORT_EMPLOYEE_FAILURE = 'IMPORT_EMPLOYEE_FAILURE';

const importEmployeeRequest = () => {
  return {
    type: IMPORT_EMPLOYEE_REQUEST
  };
};

const importEmployeeSuccess = response => {
  return {
    type: IMPORT_EMPLOYEE_SUCCESS,
    response
  };
};

const importEmployeeFailure = error => {
  return {
    type: IMPORT_EMPLOYEE_FAILURE,
    error
  };
};

export const importEmployee = file => {
  return async dispatch => {
    dispatch(importEmployeeRequest());
    try {
      const response = await postUpload(`${EMPLOYEE_URL}/import`, file);
      dispatch(importEmployeeSuccess(response));
      response.successCount > 0 &&
        dispatch(
          getEmployeeList({
            column: '',
            orderBy: 'updatedAt',
            direction: 'desc',
            page: 0
          })
        );
    } catch (err) {
      setError(err);
      dispatch(importEmployeeFailure(err));
    }
  };
};

export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';

export const setDefaultState = () => {
  return {
    type: SET_DEFAULT_STATE
  };
};

export const getTemplate = () => {
  return async () => {
    try {
      const response = await get_file(`${EMPLOYEE_URL}/import/template`);
      const blob = await response.blob();
      await download(blob, 'example-template.csv');
    } catch (err) {
      setError(err.message);
    }
  };
};
