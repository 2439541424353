import queryString from 'query-string';
import { get, patch, post } from '../utils';
import { GROUP_URL } from '../utils/apis';
import { setError } from '../utils/errorHandle';

export const GET_GROUP_LIST_REQUEST = 'GET_GROUP_LIST_REQUEST';
export const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAILURE = 'GET_GROUP_LIST_FAILURE';

const getGroupListRequest = () => {
  return {
    type: GET_GROUP_LIST_REQUEST
  };
};

const getGroupListSuccess = groupList => {
  return {
    type: GET_GROUP_LIST_SUCCESS,
    groupList
  };
};

const getGroupListFailure = error => {
  return {
    type: GET_GROUP_LIST_FAILURE,
    error
  };
};

export const getGroupList = query => {
  let gateGroupQuery = Object.assign({}, query, {
    [query.column]: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getGroupListRequest());
    try {
      const response = await get(
        `${GROUP_URL}/list?${queryString.stringify(gateGroupQuery)}`
      );
      dispatch(getGroupListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getGroupListFailure(err));
    }
  };
};

export const GET_GROUP_MEMBER_LIST_REQUEST = 'GET_GROUP_MEMBER_LIST_REQUEST';
export const GET_GROUP_MEMBER_LIST_SUCCESS = 'GET_GROUP_MEMBER_LIST_SUCCESS';
export const GET_GROUP_MEMBER_LIST_FAILURE = 'GET_GROUP_MEMBER_LIST_FAILURE';

const getGroupMemberListRequest = () => {
  return {
    type: GET_GROUP_MEMBER_LIST_REQUEST
  };
};

const getGroupMemberListSuccess = groupMemberList => {
  return {
    type: GET_GROUP_MEMBER_LIST_SUCCESS,
    groupMemberList
  };
};

const getGroupMemberListFailure = error => {
  return {
    type: GET_GROUP_MEMBER_LIST_FAILURE,
    error
  };
};

export const getGroupMemberList = query => {
  let groupMemberQuery = Object.assign({}, query, {
    [query.column]: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getGroupMemberListRequest());
    try {
      const response = await get(
        `${GROUP_URL}/member/list?${queryString.stringify(groupMemberQuery)}`
      );
      dispatch(getGroupMemberListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getGroupMemberListFailure(err));
    }
  };
};

export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE';

const editGroupRequest = () => {
  return {
    type: EDIT_GROUP_REQUEST
  };
};

const editGroupSuccess = response => {
  return {
    type: EDIT_GROUP_SUCCESS,
    response
  };
};

const editGroupFailure = error => {
  return {
    type: EDIT_GROUP_FAILURE,
    error
  };
};

export const editGroup = group => {
  return async dispatch => {
    dispatch(editGroupRequest());
    try {
      const response = await patch(`${GROUP_URL}`, group);
      dispatch(editGroupSuccess(response));
      dispatch(getGroupMemberList({ id: response.id }));
    } catch (err) {
      setError(err.message);
      dispatch(editGroupFailure(err));
    }
  };
};

export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';

const addGroupRequest = () => {
  return {
    type: ADD_GROUP_REQUEST
  };
};

const addGroupSuccess = group => {
  return {
    type: ADD_GROUP_SUCCESS,
    group
  };
};

const addGroupFailure = error => {
  return {
    type: ADD_GROUP_FAILURE,
    error
  };
};

export const addGroup = group => {
  return async dispatch => {
    dispatch(addGroupRequest());
    try {
      const response = await post(`${GROUP_URL}`, group);
      dispatch(addGroupSuccess(response));
      dispatch(getGroupMemberList({ id: response.id }));
    } catch (err) {
      setError(err.message);
      dispatch(addGroupFailure(err));
    }
  };
};

export const DELETE_GROUP_MEMBER_REQUEST = 'DELETE_GROUP_MEMBER_REQUEST';
export const DELETE_GROUP_MEMBER_SUCCESS = 'DELETE_GROUP_MEMBER_SUCCESS';
export const DELETE_GROUP_MEMBER_FAILURE = 'DELETE_GROUP_MEMBER_FAILURE';

const deleteGroupMemberRequest = () => {
  return {
    type: DELETE_GROUP_MEMBER_REQUEST
  };
};

const deleteGroupMemberSuccess = response => {
  return {
    type: DELETE_GROUP_MEMBER_SUCCESS,
    response
  };
};

const deleteGroupMemberFailure = error => {
  return {
    type: DELETE_GROUP_MEMBER_FAILURE,
    error
  };
};

export const deleteGroupMember = group => {
  return async dispatch => {
    dispatch(deleteGroupMemberRequest());
    try {
      const response = await post(`${GROUP_URL}/member/delete`, group);
      dispatch(deleteGroupMemberSuccess(response));
      dispatch(getGroupMemberList({ id: group.id }));
    } catch (err) {
      setError(err.message);
      dispatch(deleteGroupMemberFailure(err));
    }
  };
};

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

const deleteGroupRequest = () => {
  return {
    type: DELETE_GROUP_REQUEST
  };
};

const deleteGroupSuccess = response => {
  return {
    type: DELETE_GROUP_SUCCESS,
    response
  };
};

const deleteGroupFailure = error => {
  return {
    type: DELETE_GROUP_FAILURE,
    error
  };
};

export const deleteGroup = group => {
  let body = {
    ids: group
  };
  return async dispatch => {
    dispatch(deleteGroupRequest());
    try {
      const response = await post(`${GROUP_URL}/delete`, body);
      dispatch(deleteGroupSuccess(response));
      dispatch(getGroupList({ column: '' }));
    } catch (err) {
      console.log(err);
      setError(err.message);
      dispatch(deleteGroupFailure(err));
    }
  };
};

export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';

export const setDefaultState = () => {
  return {
    type: SET_DEFAULT_STATE
  };
};
