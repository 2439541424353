import { fromJS } from 'immutable';
import { clearError } from '../utils/errorHandle';
import {
  GET_DEVICE_LIST_REQUEST,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_FAILURE,
  EDIT_DEVICE_REQUEST,
  EDIT_DEVICE_SUCCESS,
  EDIT_DEVICE_FAILURE
} from '../actions/deviceAction';

const initialState = fromJS({
  deviceList: {},
  loading: false,
  error: '',
  saveSuccess: false
});

const device = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_LIST_REQUEST: {
      clearError();
      return state.set('loading', true);
    }
    case GET_DEVICE_LIST_SUCCESS: {
      return state.set('deviceList', action.deviceList).set('loading', false);
    }
    case GET_DEVICE_LIST_FAILURE: {
      return state.set('error', action.error).set('loading', false);
    }
    case EDIT_DEVICE_REQUEST: {
      clearError();
      return state.set('error', '').set('saveSuccess', false);
    }
    case EDIT_DEVICE_SUCCESS: {
      return state.set('saveSuccess', true);
    }
    case EDIT_DEVICE_FAILURE: {
      return state.set('error', action.error).set('saveSuccess', false);
    }
    default: {
      return state;
    }
  }
};

export default device;
