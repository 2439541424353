import queryString from 'query-string';
import { get, post, patch } from '../utils';
import { USER_URL } from '../utils/apis';
import { setError } from '../utils/errorHandle';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

const getUserListRequest = () => {
  return {
    type: GET_USER_LIST_REQUEST
  };
};

const getUserListSuccess = userList => {
  return {
    type: GET_USER_LIST_SUCCESS,
    userList
  };
};

const getUserListFailure = error => {
  return {
    type: GET_USER_LIST_FAILURE,
    error
  };
};

export const getUserList = query => {
  let userQuery = Object.assign({}, query, {
    [query.column]: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getUserListRequest());
    try {
      const response = await get(
        `${USER_URL}/list?${queryString.stringify(userQuery)}`
      );
      dispatch(getUserListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getUserListFailure(err));
    }
  };
};

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const HIDE_SUCCESS_MODAL = 'HIDE_SUCCESS_MODAL';

const inviteUserRequest = () => {
  return {
    type: INVITE_USER_REQUEST
  };
};

const inviteUserSuccess = () => {
  return {
    type: INVITE_USER_SUCCESS
  };
};

const inviteUserFailure = error => {
  return {
    type: INVITE_USER_FAILURE,
    error
  };
};

const hideSuccessAddUserModal = () => {
  return {
    type: HIDE_SUCCESS_MODAL
  };
};

export const inviteUser = user => {
  return async dispatch => {
    dispatch(inviteUserRequest());
    try {
      const response = await post(`${USER_URL}/invite`, user);
      dispatch(inviteUserSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(inviteUserFailure(err));
    }
  };
};

export const INVITE_EMPLOYEE_REQUEST = 'INVITE_EMPLOYEE_REQUEST';
export const INVITE_EMPLOYEE_SUCCESS = 'INVITE_EMPLOYEE_SUCCESS';
export const INVITE_EMPLOYEE_FAILURE = 'INVITE_EMPLOYEE_FAILURE';

const inviteEmployeeRequest = () => {
  return {
    type: INVITE_EMPLOYEE_REQUEST
  };
};

const inviteEmployeeSuccess = () => {
  return {
    type: INVITE_EMPLOYEE_SUCCESS
  };
};

const inviteEmployeeFailure = error => {
  return {
    type: INVITE_EMPLOYEE_FAILURE,
    error
  };
};

export const inviteEmployee = email => {
  return async dispatch => {
    dispatch(inviteEmployeeRequest());
    try {
      const response = await post(`${USER_URL}/invite/employee`, email);
      dispatch(inviteEmployeeSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(inviteEmployeeFailure(err));
    }
  };
};

export const hideSuccessModal = () => {
  return dispatch => {
    dispatch(hideSuccessAddUserModal());
  };
};

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

const addUserRequest = () => {
  return {
    type: ADD_USER_REQUEST
  };
};

const addUserSuccess = response => {
  return {
    type: ADD_USER_SUCCESS,
    response
  };
};

const addUserFailure = error => {
  return {
    type: ADD_USER_FAILURE,
    error
  };
};

export const addUser = user => {
  return async dispatch => {
    dispatch(addUserRequest());
    try {
      const response = await post(`${USER_URL}`, user);
      dispatch(addUserSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(addUserFailure(err));
    }
  };
};

export const EDIT_ROLE_REQUEST = 'EDIT_ROLE_REQUEST';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE';

const editRoleRequest = () => {
  return {
    type: EDIT_ROLE_REQUEST
  };
};

const editRoleSuccess = response => {
  return {
    type: EDIT_ROLE_SUCCESS,
    response
  };
};

const editRoleFailure = error => {
  return {
    type: EDIT_ROLE_FAILURE,
    error
  };
};

export const editRole = user => {
  return async dispatch => {
    dispatch(editRoleRequest());
    try {
      const response = await patch(`${USER_URL}/role`, user);
      dispatch(editRoleSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(editRoleFailure(err));
    }
  };
};

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

const changePasswordRequest = () => {
  return {
    type: CHANGE_PASSWORD_REQUEST
  };
};

const changePasswordSuccess = response => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    response
  };
};

const changePasswordFailure = error => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    error
  };
};

export const changePassword = user => {
  return async dispatch => {
    dispatch(changePasswordRequest());
    try {
      const response = await patch(`${USER_URL}/changePassword`, user);
      dispatch(changePasswordSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(changePasswordFailure(err));
    }
  };
};

export const SET_CHANGE_PASSWORD_TO_FALSE = 'SET_CHANGE_PASSWORD_TO_FALSE';

export const setChangePasswordToFalse = () => {
  return {
    type: SET_CHANGE_PASSWORD_TO_FALSE
  };
};

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST
  };
};

const deleteUserSuccess = user => {
  return {
    type: DELETE_USER_SUCCESS,
    user
  };
};

const deleteUserFailure = error => {
  return {
    type: DELETE_USER_FAILURE,
    error
  };
};

export const deleteUser = data => {
  let body = {
    emails: data
  };
  return async dispatch => {
    dispatch(deleteUserRequest());
    try {
      const response = await post(`${USER_URL}/delete`, body);
      dispatch(deleteUserSuccess(response));
      dispatch(getUserList({ page: 0 }));
    } catch (err) {
      setError(err.message);
      dispatch(deleteUserFailure(err));
    }
  };
};
