import { get, post } from '../utils';
import { USER_URL } from '../utils/apis';
import { setToken } from '../utils/tokenService';
import { setError } from '../utils/errorHandle';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

const loginFailure = error => {
  return {
    type: LOGIN_FAILURE,
    error
  };
};

export const login = data => {
  return async dispatch => {
    dispatch(loginRequest());
    try {
      const response = await post(`${USER_URL}/login`, data);
      setToken(response);
      dispatch(loginSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(loginFailure(err));
    }
  };
};

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

const getCurrentUserRequest = () => {
  return {
    type: GET_CURRENT_USER_REQUEST
  };
};

const getCurrentUserSuccess = user => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    user
  };
};

const getCurrentUserFailure = error => {
  return {
    type: GET_CURRENT_USER_FAILURE,
    error
  };
};

export const getCurrentUser = () => {
  return async dispatch => {
    dispatch(getCurrentUserRequest());
    try {
      const response = await get(`${USER_URL}`);
      dispatch(getCurrentUserSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getCurrentUserFailure(err));
    }
  };
};

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const logoutFailure = error => {
  return {
    type: LOGOUT_FAILURE,
    error
  };
};

export const logout = () => {
  return async dispatch => {
    dispatch(logoutRequest());
    try {
      dispatch(logoutSuccess());
    } catch (err) {
      setError(err.message);
      dispatch(logoutFailure(err));
    }
  };
};
