import { fromJS } from 'immutable';
import { clearToken } from '../utils/tokenService';
import { clearError } from '../utils/errorHandle';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from '../actions/accountAction';
import { GET_DEPARTMENT_REQUEST } from '../actions/employeeAction';

const initialState = fromJS({
  currentUser: null,
  isLogin: false,
  loginFail: null
});

const account = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      clearError();
      return state;
    }
    case LOGIN_SUCCESS: {
      return state.set('isLogin', true).set('loginFail', false);
    }
    case LOGIN_FAILURE: {
      return state.set('loginFail', action.error).set('isLogin', false);
    }
    case GET_CURRENT_USER_REQUEST: {
      clearError();
      return state;
    }
    case GET_CURRENT_USER_SUCCESS: {
      return state.set('currentUser', action.user).set('isLogin', false);
    }
    case GET_CURRENT_USER_FAILURE: {
      return state.set('error', action.error);
    }
    case LOGOUT_REQUEST: {
      return state;
    }
    case LOGOUT_SUCCESS: {
      clearToken();
      return state.set('isLogin', false).set('currentUser', null);
    }
    case LOGOUT_FAILURE: {
      return state.set('error', action.error);
    }
    case GET_DEPARTMENT_REQUEST: {
      return state.set('isLogin', false);
    }
    default: {
      return state;
    }
  }
};

export default account;
