import queryString from 'query-string';
import { get, patch } from '../utils';
import { DEVICE_URL } from '../utils/apis';
import { setError } from '../utils/errorHandle';

export const GET_DEVICE_LIST_REQUEST = 'GET_DEVICE_LIST_REQUEST';
export const GET_DEVICE_LIST_SUCCESS = 'GET_DEVICE_LIST_SUCCESS';
export const GET_DEVICE_LIST_FAILURE = 'GET_DEVICE_LIST_FAILURE';

const getDeviceListRequest = () => {
  return {
    type: GET_DEVICE_LIST_REQUEST
  };
};

const getDeviceListSuccess = deviceList => {
  return {
    type: GET_DEVICE_LIST_SUCCESS,
    deviceList
  };
};

const getDeviceListFailure = error => {
  return {
    type: GET_DEVICE_LIST_FAILURE,
    error
  };
};

export const getDeviceList = query => {
  let deviceQuery = Object.assign({}, query, {
    id: query.queryString || undefined
  });
  return async dispatch => {
    dispatch(getDeviceListRequest());
    try {
      const response = await get(
        `${DEVICE_URL}/list?${queryString.stringify(deviceQuery)}`
      );
      dispatch(getDeviceListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getDeviceListFailure(err));
    }
  };
};

export const EDIT_DEVICE_REQUEST = 'EDIT_DEVICE_REQUEST';
export const EDIT_DEVICE_SUCCESS = 'EDIT_DEVICE_SUCCESS';
export const EDIT_DEVICE_FAILURE = 'EDIT_DEVICE_FAILURE';

const editDeviceRequest = () => {
  return {
    type: EDIT_DEVICE_REQUEST
  };
};

const editDeviceSuccess = response => {
  return {
    type: EDIT_DEVICE_SUCCESS,
    response
  };
};

const editDeviceFailure = error => {
  return {
    type: EDIT_DEVICE_FAILURE,
    error
  };
};

export const editDevice = device => {
  return async dispatch => {
    dispatch(editDeviceRequest());
    try {
      const response = await patch(`${DEVICE_URL}`, device);
      dispatch(editDeviceSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(editDeviceFailure(err));
    }
  };
};
