import { fromJS } from 'immutable';
import { clearError } from '../utils/errorHandle';
import {
  GET_VISITOR_LIST_REQUEST,
  GET_VISITOR_LIST_SUCCESS,
  GET_VISITOR_LIST_FAILURE,
  ADD_VISITOR_REQUEST,
  ADD_VISITOR_SUCCESS,
  ADD_VISITOR_FAILURE,
  EDIT_VISITOR_REQUEST,
  EDIT_VISITOR_SUCCESS,
  EDIT_VISITOR_FAILURE,
  DELETE_VISITOR_REQUEST,
  DELETE_VISITOR_SUCCESS,
  DELETE_VISITOR_FAILURE,
  GET_VISITOR_REQUEST,
  GET_VISITOR_SUCCESS,
  GET_VISITOR_FAILURE,
  GET_TIME_STAMP_REQUEST,
  GET_TIME_STAMP_SUCCESS,
  GET_TIME_STAMP_FAILURE,
  GET_EXPORT_TIME_STAMP_REQUEST,
  GET_EXPORT_TIME_STAMP_SUCCESS,
  GET_EXPORT_TIME_STAMP_FAILURE
} from '../actions/visitorAction';
import { SET_DEFAULT_STATE } from '../actions/employeeAction';

const initialState = fromJS({
  visitorList: {},
  visitorDetail: {},
  timestamp: {},
  isLoading: false,
  isImportLoading: false,
  department: [],
  count: 0,
  importStatus: {},
  saveSuccess: false,
  deleteSuccess: false,
  newVisitor: [],
  isGetVisitorDetail: false
});

const visitor = (state = initialState, action) => {
  switch (action.type) {
    case GET_VISITOR_LIST_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_VISITOR_LIST_SUCCESS: {
      return state
        .set('visitorList', action.visitorList)
        .set('isLoading', false);
    }
    case GET_VISITOR_LIST_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case ADD_VISITOR_REQUEST: {
      clearError();
      return state.set('saveSuccess', false).set('deleteSuccess', false);
    }
    case ADD_VISITOR_SUCCESS: {
      return state
        .set('saveSuccess', true)
        .set('newVisitor', [action.visitor.uid]);
    }
    case ADD_VISITOR_FAILURE: {
      return state.set('error', action.error).set('saveSuccess', false);
    }
    case EDIT_VISITOR_REQUEST: {
      clearError();
      return state.set('saveSuccess', false);
    }
    case EDIT_VISITOR_SUCCESS: {
      return state
        .set('visitorDetail', action.visitor)
        .set('saveSuccess', true);
    }
    case EDIT_VISITOR_FAILURE: {
      return state.set('error', action.error).set('saveSuccess', false);
    }
    case DELETE_VISITOR_REQUEST: {
      clearError();
      return state.set('deleteSuccess', false);
    }
    case DELETE_VISITOR_SUCCESS: {
      return state.set('deleteSuccess', true);
    }
    case DELETE_VISITOR_FAILURE: {
      return state.set('error', action.error).set('deleteSuccess', false);
    }
    case GET_VISITOR_REQUEST: {
      clearError();
      return state
        .set('isLoading', true)
        .set('saveSuccess', false)
        .set('deleteSuccess', false)
        .set('isGetVisitorDetail', false);
    }
    case GET_VISITOR_SUCCESS: {
      return state
        .set('visitorDetail', action.visitor)
        .set('isLoading', false)
        .set('isGetVisitorDetail', true);
    }
    case GET_VISITOR_FAILURE: {
      return state
        .set('error', action.error)
        .set('isLoading', false)
        .set('isGetVisitorDetail', false);
    }
    case GET_TIME_STAMP_REQUEST: {
      clearError();
      return state.set('timestamp', {
        isLoading: true
      });
    }
    case GET_TIME_STAMP_SUCCESS: {
      return state.set('timestamp', {
        table: action.timestamp,
        isLoading: false
      });
    }
    case GET_TIME_STAMP_FAILURE: {
      return state.set('error', action.error).set('timestamp', {
        isLoading: false
      });
    }
    case GET_EXPORT_TIME_STAMP_REQUEST: {
      clearError();
      return state.set('isLoading', true);
    }
    case GET_EXPORT_TIME_STAMP_SUCCESS: {
      return state.set('isLoading', false);
    }
    case GET_EXPORT_TIME_STAMP_FAILURE: {
      return state.set('error', action.error).set('isLoading', false);
    }
    case SET_DEFAULT_STATE: {
      return state
        .set('saveSuccess', false)
        .set('deleteSuccess', false)
        .set('isLoading', false)
        .set('isImportLoading', false)
        .set('newVisitor', [])
        .set('isGetVisitorDetail', false);
    }
    default: {
      return state;
    }
  }
};

export default visitor;
