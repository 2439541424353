import moment from 'moment';
import { get, post, patch, get_file, download } from '../utils';
import { VISITOR_URL, TIME_STAMP_URL } from '../utils/apis';
import { setError } from '../utils/errorHandle';

export const GET_VISITOR_LIST_REQUEST = 'GET_VISITOR_LIST_REQUEST';
export const GET_VISITOR_LIST_SUCCESS = 'GET_VISITOR_LIST_SUCCESS';
export const GET_VISITOR_LIST_FAILURE = 'GET_VISITOR_LIST_FAILURE';

const getVisitorListRequest = () => {
  return {
    type: GET_VISITOR_LIST_REQUEST
  };
};

const getVisitorListSuccess = visitorList => {
  return {
    type: GET_VISITOR_LIST_SUCCESS,
    visitorList
  };
};

const getVisitorListFailure = error => {
  return {
    type: GET_VISITOR_LIST_FAILURE,
    error
  };
};

export const getVisitorList = query => {
  let { page, orderBy, direction, column, queryString, pageSize } = query || '';
  return async dispatch => {
    dispatch(getVisitorListRequest());
    try {
      const response = await get(
        `${VISITOR_URL}/list?page=${page}&orderBy=${orderBy ||
          'updatedAt'}&direction=${direction ||
          'desc'}&${column}=${queryString}&pageSize=${pageSize}`
      );
      dispatch(getVisitorListSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getVisitorListFailure(err));
    }
  };
};

export const ADD_VISITOR_REQUEST = 'ADD_VISITOR_REQUEST';
export const ADD_VISITOR_SUCCESS = 'ADD_VISITOR_SUCCESS';
export const ADD_VISITOR_FAILURE = 'ADD_VISITOR_FAILURE';

const addVisitorRequest = () => {
  return {
    type: ADD_VISITOR_REQUEST
  };
};

const addVisitorSuccess = visitor => {
  return {
    type: ADD_VISITOR_SUCCESS,
    visitor
  };
};

const addVisitorFailure = error => {
  return {
    type: ADD_VISITOR_FAILURE,
    error
  };
};

export const addVisitor = data => {
  return async dispatch => {
    dispatch(addVisitorRequest());
    try {
      const response = await post(`${VISITOR_URL}`, data);
      dispatch(addVisitorSuccess(response));
      dispatch(getVisitorList());
    } catch (err) {
      setError(err.message);
      dispatch(addVisitorFailure(err));
    }
  };
};

export const EDIT_VISITOR_REQUEST = 'EDIT_VISITOR_REQUEST';
export const EDIT_VISITOR_SUCCESS = 'EDIT_VISITOR_SUCCESS';
export const EDIT_VISITOR_FAILURE = 'EDIT_VISITOR_FAILURE';

const editVisitorRequest = () => {
  return {
    type: EDIT_VISITOR_REQUEST
  };
};

const editVisitorSuccess = visitor => {
  return {
    type: EDIT_VISITOR_SUCCESS,
    visitor
  };
};

const editVisitorFailure = error => {
  return {
    type: EDIT_VISITOR_FAILURE,
    error
  };
};

export const editVisitor = data => {
  return async dispatch => {
    dispatch(editVisitorRequest());
    try {
      const response = await patch(`${VISITOR_URL}`, data);
      dispatch(editVisitorSuccess(response));
      dispatch(getVisitorList());
    } catch (err) {
      setError(err.message);
      dispatch(editVisitorFailure(err));
    }
  };
};

export const DELETE_VISITOR_REQUEST = 'DELETE_VISITOR_REQUEST';
export const DELETE_VISITOR_SUCCESS = 'DELETE_VISITOR_SUCCESS';
export const DELETE_VISITOR_FAILURE = 'DELETE_VISITOR_FAILURE';

const deleteVisitorRequest = () => {
  return {
    type: DELETE_VISITOR_REQUEST
  };
};

const deleteVisitorSuccess = visitor => {
  return {
    type: DELETE_VISITOR_SUCCESS,
    visitor
  };
};

const deleteVisitorFailure = error => {
  return {
    type: DELETE_VISITOR_FAILURE,
    error
  };
};

export const deleteVisitor = data => {
  let body = {
    emails: data
  };
  return async dispatch => {
    dispatch(deleteVisitorRequest());
    try {
      const response = await post(`${VISITOR_URL}/delete`, body);
      dispatch(deleteVisitorSuccess(response));
      dispatch(getVisitorList());
    } catch (err) {
      setError(err.message);
      dispatch(deleteVisitorFailure(err));
    }
  };
};

export const GET_VISITOR_REQUEST = 'GET_VISITOR_REQUEST';
export const GET_VISITOR_SUCCESS = 'GET_VISITOR_SUCCESS';
export const GET_VISITOR_FAILURE = 'GET_VISITOR_FAILURE';

const getVisitorRequest = () => {
  return {
    type: GET_VISITOR_REQUEST
  };
};

const getVisitorSuccess = visitor => {
  return {
    type: GET_VISITOR_SUCCESS,
    visitor
  };
};

const getVisitorFailure = error => {
  return {
    type: GET_VISITOR_FAILURE,
    error
  };
};

export const getVisitor = email => {
  return async dispatch => {
    dispatch(getVisitorRequest());
    try {
      const response = await get(`${VISITOR_URL}/detail?email=${email}`);
      dispatch(getVisitorSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getVisitorFailure(err));
    }
  };
};

export const GET_TIME_STAMP_REQUEST = 'GET_TIME_STAMP_REQUEST';
export const GET_TIME_STAMP_SUCCESS = 'GET_TIME_STAMP_SUCCESS';
export const GET_TIME_STAMP_FAILURE = 'GET_TIME_STAMP_FAILURE';

const getTimestampRequest = () => {
  return {
    type: GET_TIME_STAMP_REQUEST
  };
};

const getTimestampSuccess = timestamp => {
  return {
    type: GET_TIME_STAMP_SUCCESS,
    timestamp
  };
};

const getTimestampFailure = error => {
  return {
    type: GET_TIME_STAMP_FAILURE,
    error
  };
};

export const getTimestamp = uid => {
  return async dispatch => {
    dispatch(getTimestampRequest());
    try {
      const response = await get(`${TIME_STAMP_URL}/workinghours?uid=${uid}`);
      dispatch(getTimestampSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getTimestampFailure(err));
    }
  };
};

export const GET_EXPORT_TIME_STAMP_REQUEST = 'GET_EXPORT_TIME_STAMP_REQUEST';
export const GET_EXPORT_TIME_STAMP_SUCCESS = 'GET_EXPORT_TIME_STAMP_SUCCESS';
export const GET_EXPORT_TIME_STAMP_FAILURE = 'GET_EXPORT_TIME_STAMP_FAILURE';

const getExportTimestampRequest = () => {
  return {
    type: GET_EXPORT_TIME_STAMP_REQUEST
  };
};

const getExportTimestampSuccess = () => {
  return {
    type: GET_EXPORT_TIME_STAMP_SUCCESS
  };
};

const getExportTimestampFailure = error => {
  return {
    type: GET_EXPORT_TIME_STAMP_FAILURE,
    error
  };
};

export const getExportTimestamp = data => {
  return async dispatch => {
    dispatch(getExportTimestampRequest());
    try {
      const response = await get_file(
        `${TIME_STAMP_URL}/workinghours/export?uid=${data.uid}`
      );
      const blob = await response.blob();
      await download(
        blob,
        `export_${data.uid}_${moment().format('L')}.${data.fileType}`
      );
      dispatch(getExportTimestampSuccess(response));
    } catch (err) {
      setError(err.message);
      dispatch(getExportTimestampFailure(err));
    }
  };
};

export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';

export const setDefaultState = () => {
  return {
    type: SET_DEFAULT_STATE
  };
};
